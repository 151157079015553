//Include on every page - listen for popup submit to handle hiding
(function($) {

  const wpformsBindIcegram = ($form) => {
    wpforms.init();
    wpformsRecaptchaLoad();
  }

  $(window).on("init.icegram",() => {
    window.icegram.messages.forEach(popup => {
      const $email_form = $(popup.el).find(".ig_message .wpforms-form");
      if ($email_form.length) {

        wpformsBindIcegram($email_form);

        //WP forms email signup submit tracking
        const handleEmailFormResponse = (e, req, opts) => {

          //Intercept AJAX requests
          if (req.responseJSON
            && req.responseJSON.data 
            && req.responseJSON.data.confirmation 
            && req.responseJSON.data.confirmation.indexOf('wpforms-confirmation') !== -1 
            && req.responseJSON.success) {

            //Fire once per successful submit
            $(document).off('ajaxComplete', handleEmailFormResponse);

            //Handle hiding
            setTimeout(() => {
              popup.hide();
            },1000);

            //The request was successful
            if (req && (req.status == 200 || req.status == 204)) {
              const event = {
                form_type: 'email_signup',
                email_form: 'Popup',
                topic: window.stws_2021_campaigns ? stws_2021_campaigns.post_topic : '',
                user_id: btoa($email_field.val()),
                event: 'generate_lead'
              };
              //console.log(event);
              dataLayer.push(event);
            } else {
              console.error(req.response);
            }
          }
        }
        
        const $email_field = $email_form.find("input[type='email']");
        
        $email_form.on("submit", () => {
          $(document).on('ajaxComplete',handleEmailFormResponse);
        })
      }
    })
  });
})(jQuery);